import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {AutocompleteZts} from "../../base/autocomplete";
import IconButton from "@mui/material/IconButton";
import {AutocompleteGenericOption, createAutocompleteGenericOption} from "../../base/autocomplete/model";
import {useTranslation} from "react-i18next";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {NewExpenseFormValues} from "./validation";
import {getColleagues, getUsers} from "../../users/Service";
import {Delete} from "@mui/icons-material";
import {TextFieldZts} from "../../base/text-field";

type ComponentProps = {
  staffId: number,
  editableAndReadonly?: boolean,
  formArrayName?: "colleagues" | `additionalExpenses.${number}.colleagues`
}

export const Colleagues = ({staffId, editableAndReadonly, formArrayName = "colleagues"}: ComponentProps) => {

  const formMethods = useFormContext<NewExpenseFormValues>();

  const {fields, append, replace} = useFieldArray({
    name: formArrayName,
    control: formMethods.control
  });

  const {t} = useTranslation('exp-note', {keyPrefix: 'expense.edit'});

  const colleagues = useWatch({name: formArrayName, exact: true});

  const [colleagueItems, setColleagueItems] = useState<AutocompleteGenericOption[] | null>(null);

  // aggiunge la prima riga vuota
  useEffect(() => {
    if (!editableAndReadonly) {
      append({colleague: null}, {shouldFocus: false});
    }
  }, []);

  const loadColleagues = async () => {
    if (colleagueItems) {
      return;
    }
    const order = 'FIRST_NAME,LAST_NAME';
    const users = await getColleagues(order);
    if (users && users.length > 0) {
      setColleagueItems(users
        .filter(u => u.id !== staffId)
        .filter(u => u.state === "EF")
        .map(u => createAutocompleteGenericOption(u.id, u.id.toString(), `${u.firstName} ${u.lastName}`)));
    } else {
      setColleagueItems([]);
    }
  }

  const handleAddNewColleague = (colleagues: AutocompleteGenericOption[], pushFunc: Function) => {
    if (colleagues.findIndex(c => c == null) === -1) {
      pushFunc(null);
    }
  }

  const handleDeleteColleague = (index: number) => {
    if (colleagues.length === 1) {
      formMethods.setValue(`${formArrayName}.0.colleague`, null);
    } else {
      removeRow(index);
    }
  }

  const removeRow = (index) => {
    const values = colleagues;
    if (values) {
      replace(values.filter((_, idx) => index !== idx));
    }
  }

  const forceValidation = () => {
    formMethods.trigger(`${formArrayName}`);
  }

  useEffect(() => {
    forceValidation();
  }, [fields]);

  return (
    <>
      {editableAndReadonly && fields.length === 0 ? (
        <></>
      ) : (
        <>
          <Typography mb={1} variant={"body2"}>{t('colleague', {count: 0})}</Typography>
          <Grid
            container
            rowSpacing={1}
            alignItems={"center"}
          >
            <>
              {fields.map((item, index) => (
                <React.Fragment key={item.id}>
                  {editableAndReadonly ? (
                    <Grid item xs={12}>
                      <TextFieldZts
                        disabled={true}
                        hiddenLabel={true}
                        value={formMethods.getValues(`${formArrayName}.${index}.colleague`)?.selectedLabel}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Controller
                        name={`${formArrayName}.${index}.colleague`}
                        control={formMethods.control}
                        render={({field, fieldState}) => {
                          return <AutocompleteZts
                            id={field.name}
                            hiddenLabel={true}
                            label=''
                            lazyLoadOptions={loadColleagues}
                            options={colleagueItems}
                            selectedValue={field.value}
                            setValue={(name, value) => {
                              //console.log(name, value);
                              field.onChange(value);
                              if (value) {
                                append({colleague: null});
                              } else {
                                removeRow(index);
                              }
                            }}
                            codeAndDesc={false}
                            errorMsg={fieldState.error?.message}
                            clearIcon={<Delete
                              className={"text-danger"}
                              fontSize={"small"}
                            />}
                          />
                        }}
                      />
                    </Grid>
                    // <Grid item xs={1}>
                    //   {formMethods.getValues(`${formArrayName}.${index}.colleague`) &&
                    //     <IconButton
                    //       size={"small"}
                    //       onClick={() => handleDeleteColleague(index)}
                    //     >
                    //       <Delete
                    //         className={"text-danger"}
                    //         fontSize={"small"}
                    //       />
                    //     </IconButton>}
                    // </Grid>
                  )
                  }
                </React.Fragment>
              ))}

              {/*<Grid item xs={3}>*/}
              {/*</Grid>*/}
              {/*<Grid item xs={9} mt={2}>*/}
              {/*  <Button*/}
              {/*    color={"primary"}*/}
              {/*    variant="outlined"*/}
              {/*    onClick={() => handleAddNewColleague(formikProps.values.colleagues, push)}*/}
              {/*  >*/}
              {/*    <Add />*/}
              {/*  </Button>*/}
              {/*</Grid>*/}
            </>
          </Grid>
        </>
      )}

    </>
  );
}
