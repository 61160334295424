import {ReactChild, useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";

type ComponentProps<T> = {
  scrollableTargetId: string,
  elements: T[];
  renderElement: (element: T) => ReactChild,
  pageSize?: number,
  style?: any,
}

export const InfiniteScrollZts = <T extends object>(props: ComponentProps<T>) => {

  const {
    scrollableTargetId,
    elements,
    renderElement,
    pageSize = 100,
    style
  } = props;

  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(0);

  const fetchMoreData = () => {
    setPage(prevPage => prevPage + 1);
    //console.log("load more")
  }

  useEffect(() => {
    setDataLength(elements ? elements.length : 0);
    setPage(1);
  }, [elements]);

  const pagedElements = elements ? elements.slice(0, page * pageSize) : [];

  return (
    <InfiniteScroll
      style={style}
      dataLength={pagedElements.length}
      next={fetchMoreData}
      hasMore={pagedElements.length < dataLength}
      loader={<h4>Loading...</h4>}
      scrollableTarget={scrollableTargetId}
    >
      {pagedElements.map(element => {
        return renderElement(element)
      })}
    </InfiniteScroll>
  );
}
