// Libs
import jwt_decode from 'jwt-decode';

// Custom
import { getUserByUserId } from '../components/users/Service';
import { UserWithTenant } from '../components/users/model';


const LOGIN_JWT_KEY = 'login.jwt';
const JWT_KEY = 'api.jwt';

export const saveLoginPortalJwt = (value: string): void => {
  sessionStorage.setItem(LOGIN_JWT_KEY, value);
}

export const getLoginPortalJwt = (): string | null => {
  return sessionStorage.getItem(LOGIN_JWT_KEY);
}

export const hasLoginPortalJwt = (): boolean => {
  return sessionStorage.getItem(LOGIN_JWT_KEY) !== null;
}

export const saveToken = (token): void => { //TODO cercare tutte le occorrenze
  sessionStorage.setItem(JWT_KEY, token);
}

export const getToken = (): string | null => {
  return sessionStorage.getItem(JWT_KEY);
}

export const removeToken = () => {
  return sessionStorage.removeItem(JWT_KEY);
}

export const getUserByToken = async (token): Promise<UserWithTenant | null> => {
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  } else {
    const storedToken = getToken();
    if (storedToken) {
      decodedToken = jwt_decode(storedToken);
    }
  }
  if (decodedToken && decodedToken.sub) {
    const user = await getUserByUserId(decodedToken.sub);

    switch (decodedToken.roleId) {
      case "1":
        user.isTraveller = true;
        break;
      case "2":
        user.isAdmin = true;
        break;
      case "3":
        user.isTraveller = true;
        user.isAdmin = true;
        break;
    }

    return user;
  }
  return null;
}

export type ZtsJwtPayload = {
  iss: string;
  tenantId: string;
  aud: string;
  sub: string;
  roleId: string;
  lic: string;
  mod?: string;
}

export const getZtsJwtPayload = (): ZtsJwtPayload | null => {
  const jwt = getToken();
  if (jwt===null) { return null; }
  // https://github.com/auth0/jwt-decode#readme
  let decodedObj: any = null;
  try {
    decodedObj = jwt_decode(jwt);
  }catch(e) {
    // Errore nella decodifica del token!
    return null;
  }
  return decodedObj as ZtsJwtPayload;
}

export const isDigitalStorageModuleEnabled = (): boolean => {
  const jwtBody = getZtsJwtPayload();
  if (!jwtBody || !jwtBody.mod) {
    return false;
  }
  const modules = jwtBody.mod.split(',');
  return modules.some(m => m === 'ds');
}

export const isCreditCardModuleEnabled = (): boolean => {
  const jwtBody = getZtsJwtPayload();
  if (!jwtBody || !jwtBody.mod) {
    return false;
  }
  const modules = jwtBody.mod.split(',');
  return modules.some(m => m === 'cc');
}
