import { activeStateCode, Currency, GenericElement, StateType } from '../model';
import { AutocompleteGenericOption } from '../base/autocomplete/model';
import { Locale } from '../../util/LocalizationUtil';
import { ExpenseCategory } from '../expenses/model';

export const MASTER_ADMIN_PREFIX = "ma";

export interface User {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  fiscalCode: string,
  matricNumber: string,
  address: string,
  cap: string,
  location: string,
  costCenter?: GenericElement,
  account?: GenericElement,
  approver?: Approver,
  lastUpdateNum: number,
  state: StateType,
  travelPolicies?: StaffTravelPolicy[],
  isAdmin: boolean,
  isTraveller: boolean,
  pictureId?: string,
  locale?: Locale,
  currency?: Currency
}

export interface Colleague {
  firstName: string,
  id: number,
  lastName: string,
  pictureId: string,
  state: StateType,
}

export interface Tenant {
  id: number,
  description: string,
  insertTime: Date,
  hasArchivedExpNote: boolean
}

export interface UserWithTenant extends User {
  tenant: Tenant
}

export interface Approver {
  id: number,
  firstName: string,
  lastName: string
}

export interface StaffTravelPolicy {
  id: number,
  travelPolicyId: number,
  code: string,
  description: string,
  startDate: Date,
  endDate: Date,
  foreignCurrEnb: boolean,
  projectEnb: boolean
}

export interface StaffCreditCard {
  id: number,
  cardNum: string,
  cardDesc: string,
  expireDate: Date | null,
  account: GenericElement
}

export interface AllValidCreditCard {
  id: number,
  staffId: number,
  cardNum: string,
  cardDesc: string,
  expireDate: Date | null
}

export interface StaffExpense {
  id: number | null,
  expId: GenericElement | null,
  tpId: GenericElement | null,
  tarif: string,
  notes: string | null
}

export interface StaffProject {
  id: number | null,
  projectId: string
}

export interface SaveUser {
  loginPortalUrl?: string,
  loginPortalToken?: string | null,
  id: number | null,
  firstName: string,
  lastName: string,
  email: string,
  fiscalCode: string,
  matricNumber: string,
  address: string,
  cap: string,
  location: string,
  costCenterId: number | null,
  accountId: number | null,
  approverId: number | null,
  lastUpdateNum: number,
  state: string,
  isAdmin: boolean,
  isTraveller: boolean,
  pictureId: string | null,
  locale: Locale
}

export interface StaffTravelPolicyItem {
  id?: number;
  travelPolicyId: number
}

export interface StaffTravelPoliciesSaveReq {
  lastUpdNum: number;
  travelPolicies: StaffTravelPolicyItem[];
}

export interface StaffCreditCardsSaveReq {
  lastUpdNum: number;
  creCards: StaffCreditCard[];
}

export interface StaffExpensesSaveReq {
  lastUpdNum: number;
  expenses: StaffExpense[];
}

export interface StaffExpenseResp {
  id: number;
  expenseId: number;
  expenseCode: string;
  expenseDesc: string;
  travelPolicyId: number;
  travelPolicyCode: string;
  travelPolicyDesc: string;
  tarif: number;
  notes: string;
}

export interface StaffAvailableTarifExpResp {
  id: number;
  code: string;
  description: string;
  expenseIcon: string;
  travelPolicyId: number;
  travelPolicyCode: string;
  travelPolicyDescription: string;
  expenseType: string;
  measureUnit: string;
}

export interface StaffExpenseItem {
  id: number;
  code: string;
  description: string;
  measureUnit: string;
}

export interface StaffTravelPolicyExpenseItem {
  id: number;
  expenseCode: string;
  expenseDescription: string;
  expenseIcon: string;
  expenseCategory: ExpenseCategory;
  expenseType: string;
  expenseId: number;
  payCollab: boolean;
  payCard: boolean;
  payCompany: boolean;
  docInvoice: boolean;
  docReceipt: boolean;
  docTicket: boolean;
  colleagueEnb: boolean;
  guestEnb: boolean;
  maximumType: string;
  tarif: number;
  attachRequired: boolean;
  localityRequired: boolean;
  notesRequired: boolean;
  projectRequired: boolean;
  carRouteEnb: boolean;
  compPeriod: boolean;
  compPeriodStartLabel?: string,
  compPeriodEndLabel?: string,
  measureUnit: string;
}

export interface StaffProjectsSaveReq {
  lastUpdNum: number;
  projects: StaffProject[];
}

export const isActiveUser = (user: User) => {
  return user && activeStateCode === user.state;
}

export const stringAvatar = (firstName: string, lastName: string) => {
  return {
    children: `${firstName && firstName.length > 0 ? firstName.charAt(0) : ''}${lastName && lastName.length > 0 ? lastName.charAt(0) : ''}`,
  };
}

export const getApproverDesc = (approver: Approver | undefined): string => {
  return approver ? `${approver.firstName} ${approver.lastName}` : '';
}

export const getUserFullName = (user: User): string => {
  return user ? `${user.firstName} ${user.lastName}` : '';
}

export const createAutocompleteApproverOption = (id: number, firstName: string, lastName: string): AutocompleteGenericOption => {
  const fullName = `${firstName} ${lastName}`;
  return {
    id,
    code: firstName,
    desc: lastName,
    icon: null,
    listLabel: fullName,
    selectedLabel: fullName
  };
}

export enum UserItemType {
  TRAVEL_POLICY,
  PROJECT,
  TARIF,
  CARD
}

export enum ListFilterType {
  SEARCH_TEXT,
  ENABLED,
  TRAVEL_POLICY,
  ROLE,
  LEVEL,
  APPROVER,
  VALID_CRE_CARD
}

export interface ListFilter {
  type: ListFilterType,
  enabled: boolean,
  label: string,
  value: any,
  hidden?: boolean,
  order?: number
}

export const ListFilters: ListFilter[] = [
  {
    type: ListFilterType.SEARCH_TEXT,
    label: '',
    enabled: true,
    hidden: true,
    value: ''
  },
  {
    type: ListFilterType.ENABLED,
    label: 'Attivi',
    enabled: true,
    value: '1'
  },
  {
    type: ListFilterType.TRAVEL_POLICY,
    label: 'list.filter.tp',
    enabled: false,
    value: null
  },
  {
    type: ListFilterType.ROLE,
    label: 'list.filter.role',
    enabled: false,
    value: null
  },
  {
    type: ListFilterType.LEVEL,
    label: 'list.filter.level',
    enabled: false,
    value: null
  },
  {
    type: ListFilterType.APPROVER,
    label: 'list.filter.approver',
    enabled: false,
    value: null
  },
  {
    type: ListFilterType.VALID_CRE_CARD,
    label: 'list.filter.creCard',
    enabled: false,
    value: null
  },
];

export enum RoleFilter {
  TRAVELLER = "T",
  ADMIN = "A"
}

export enum CreCardFilter {
  VALID_CRE_CARD = "V",
  NO_CRE_CARD = "N"
}

export const TravellerLabel = 'role.traveller';
export const AdminLabel = 'role.administrator';

export const getRoleLabel = (user: User, t): string => {
  if (user) {
    if (user.isTraveller && user.isAdmin) {
      return `${t(AdminLabel)}, ${t(TravellerLabel)}`;
    } else if (user.isTraveller) {
      return t(TravellerLabel);
    } else if (user.isAdmin) {
      return t(AdminLabel);
    }
  }
  return t("role.empty");
}

export interface LastUsedProjectResponse {
  projectId: number,
  code: string,
  description: string,
  startDate: Date,
  endDate: Date,
  lastUsed: Date
}
