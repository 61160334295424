// JS Libs
import moment from 'moment';
import axios, { AxiosRequestConfig } from 'axios';
import { Logger } from 'react-logger-lib';
import SimpleJsLog from '../../util/Logger';

// Zucchetti
import {
  AllValidCreditCard,
  Approver, Colleague,
  LastUsedProjectResponse, MASTER_ADMIN_PREFIX,
  SaveUser,
  StaffAvailableTarifExpResp,
  StaffCreditCard,
  StaffCreditCardsSaveReq,
  StaffExpenseResp,
  StaffTravelPoliciesSaveReq,
  StaffTravelPolicy,
  StaffTravelPolicyExpenseItem,
  User,
  UserWithTenant
} from './model';
import { getApiUrl, getLoginPortalUrl, isRemoteLogin } from '../../config/environment';
import { activeStateCode, DateFormat, GenericList, getStateCode } from '../model';
import { createError } from '../../util/ErrorUtil';
import { ExpNote } from '../exp_note/model';
import { getLoginPortalJwt, getToken, getZtsJwtPayload, hasLoginPortalJwt } from '../../config/token';
import { UpdateProfileRequest } from '../profile/model';



const LOG: SimpleJsLog = Logger.of('ZTS.Staff.Service');

export const getApprovers = async (): Promise<Approver[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/approvers?state=${getStateCode(true)}`);
  return resp.data.elements;
}

export const getUsers = async (order?: string): Promise<User[]> => {
  const params = new URLSearchParams();
  if (order) {
    params.append('order', order);
  }
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<User>>(`${apiUrl}/staffs`, {params});
  //console.log(resp);
  return resp.data.elements;
}

export const getColleagues = async (order?: string): Promise<Colleague[]> => {
  const params = new URLSearchParams();
  if (order) {
    params.append('order', order);
  }
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<Colleague>>(`${apiUrl}/staffs`, {params});
  //console.log(resp);
  return resp.data.elements;
}

export const deleteUser = async (index: number) => {
  const apiUrl = getApiUrl();
  let url = `${apiUrl}/${index}`;
  const resp = await fetch(url, {
    method: 'DELETE'
  });
  await resp.json();
}

export const saveUser = async (user: SaveUser) => {
  user.loginPortalUrl = getLoginPortalUrl();
  user.loginPortalToken = getLoginPortalJwt();
  if (user.id) {
    return await updateUser(user);
  } else {
    return await insertUser(user);
  }
}

type CreateUserResp = {
  id: number;
  state: string;
}

const insertUser = async (user: SaveUser): Promise<CreateUserResp> => {
  let body: CreateUserResp;
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.post<CreateUserResp>(`${apiUrl}/staffs`, user);
    body = resp.data;
  } catch (err: any) {
    throw createError(err);
  }
  if (body.state === 'AA') {
    const errorContent = JSON.stringify({errorCode: 'license.maximum'});
    throw new Error(errorContent);
  }
  return body;
}

const updateUser = async (user: SaveUser) => {
  try {
    const apiUrl = getApiUrl();
    const resp = await axios.put(`${apiUrl}/staff/${user.id}`, user);
    return resp.data;
  } catch (err: any) {
    throw createError(err);
  }
}

//vedi DisableStaffRequest.java
type DisableStaffRequestType = {
  loginPortalUrl: string;
  loginPortalJwt : string;
  staffsId: number[];
}

export const disableUsers = async (usersId: number[]): Promise<void> => {
  try {
    let jwt = 'none';
    if (hasLoginPortalJwt()) {
      jwt = getLoginPortalJwt() as string;
    }
    const apiUrl = getApiUrl();
    const body: DisableStaffRequestType = {
      loginPortalUrl: getLoginPortalUrl(),
      loginPortalJwt: jwt,
      staffsId:usersId
    }
    LOG.trace('disableUsers - Calling remote api ...');
    const resp = await axios.put(`${apiUrl}/staffs/disable`, body);
    LOG.trace('disableUsers - Resp status: ', resp.status);
  } catch (err: any) {
    LOG.trace('disableUsers - Error', err);
    throw createError(err);
  }
}

type TenantCountersResponse = {
  maxEnabledUsers: number;
  enabledUsers: number;
}

export const hasAvailableLicenses = async (): Promise<boolean> => {
  if (!isRemoteLogin()) {
    // Per lo sviluppo in locale
    return true;
  }
  const jwtPayload = getZtsJwtPayload();
  if (jwtPayload==null) { return false; }
  const loginPortalUrl = getLoginPortalUrl();
  const loginPortalJwt = getLoginPortalJwt();
  const tenantId = jwtPayload.tenantId;
  const url = `${loginPortalUrl}tenant/${tenantId}/counters`
  const axiosConfig: AxiosRequestConfig = {
    headers: {
      'Authorization': `Bearer ${loginPortalJwt}`
    }
  }
  let counters: TenantCountersResponse;
  try {
    LOG.trace('hasAvailableLicenses - Calling LOGIN PORTAL:', url);
    const resp = await axios.get<TenantCountersResponse>(url, axiosConfig);
    LOG.trace('hasAvailableLicenses - LOGIN PORTAL resp status:', resp.status);
    LOG.trace('hasAvailableLicenses - LOGIN PORTAL resp:', resp);
    counters = resp.data;
  } catch (err: any) {
    LOG.trace('hasAvailableLicenses - Error', err);
    throw createError(err);
  }
  LOG.trace('hasAvailableLicenses - counters', counters);
  return counters.maxEnabledUsers>counters.enabledUsers;
}

export const getUserById = async (id: string): Promise<User> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/staff/${id}`);
  return resp.data;
}

export const getUserByUserId = async (id: string): Promise<UserWithTenant> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<UserWithTenant>(`${apiUrl}/staff/user/${id}`);
  return resp.data;
}

export const getStaffTravelPolicies = async (id: number): Promise<StaffTravelPolicy[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<StaffTravelPolicy>>(`${apiUrl}/staff/${id}/travelpolicies`);
  return resp.data.elements;
}

export const getStaffTravelPoliciesByExpNote = async (expNote: ExpNote): Promise<StaffTravelPolicy[]> => {
  const queryString = new URLSearchParams();
  queryString.append('startDate', moment(expNote.startDate).format(DateFormat));
  queryString.append('state', activeStateCode);
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<StaffTravelPolicy>>(`${apiUrl}/staff/${expNote.staffId}/travelpolicies`, {params: queryString});
  return resp.data.elements;
}

export const getStaffTravelPolicyExpItems = async (staffId: number, tpId: number): Promise<StaffTravelPolicyExpenseItem[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<StaffTravelPolicyExpenseItem[]>(`${apiUrl}/staff/${staffId}/travelpolicies/${tpId}/expenses`);
  return resp.data;
}

export const getStaffCreditCards = async (id: number): Promise<StaffCreditCard[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/staff/${id}/crecards`);
  return resp.data.elements;
}

export const saveStaffCreditCards = async (staffId: number, data: StaffCreditCardsSaveReq) => {
  const apiUrl = getApiUrl();
  await axios.post(`${apiUrl}/staff/${staffId}/allcrecards`, data);
}

export const saveStaffTravelPolicies = async (staffId: number, data: StaffTravelPoliciesSaveReq) => {
  const apiUrl = getApiUrl();
  await axios.post(`${apiUrl}/staff/${staffId}/travelpolicies`, data);
}

export const getAvailableTarifExpenses = async (staffId: number): Promise<StaffAvailableTarifExpResp[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/tarifexpenses/${staffId}`);
  return resp.data.elements;
}

export const getStaffExpenses = async (id: number): Promise<StaffExpenseResp[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/staff/${id}/expenses`);
  return resp.data.elements;
}

export const saveStaffExpenses = async (staffId: number, data) => {
  const apiUrl = getApiUrl();
  try {
    await axios.post(`${apiUrl}/staff/${staffId}/allexpenses`, data);
  } catch (err: any) {
    if (err.response) {
      throw new Error(err.response.data.errorMessage);
    }
    throw err;
  }
}

export const getUserPicture = async (staffId: number, pictureId: string): Promise<Blob> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<Blob>(`${apiUrl}/staff/${staffId}/picture/${pictureId}`, {responseType: 'blob'});
  //console.log(resp);
  return resp.data;
}

export const getFilteredProjects = async (expNote: ExpNote): Promise<LastUsedProjectResponse[]> => {
  const queryString = new URLSearchParams();
  queryString.append('startDate', moment(expNote.startDate).format(DateFormat));
  queryString.append('state', activeStateCode);
  const apiUrl = getApiUrl();
  const resp = await axios.get<GenericList<LastUsedProjectResponse>>(`${apiUrl}/staff/${expNote.staffId}/filteredprojects`, {params: queryString});
  //console.log(resp);
  return resp.data.elements;
}

export const isApprover = async (): Promise<boolean> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get<{ count: number }>(`${apiUrl}/staff/countapprove`);
  return resp.data.count > 0;
}

export const getMasterAdminId = async (tenantId: number): Promise<number> => {
  const storageKey = `${MASTER_ADMIN_PREFIX}_${tenantId}`;
  const storageId = sessionStorage.getItem(storageKey);
  if (storageId) {
    return +storageId;
  } else {
    const apiUrl = getApiUrl();
    const resp = await axios.get<number>(`${apiUrl}/staff/master`);
    const id = resp.data;
    sessionStorage.setItem(storageKey, id.toString());
    return id;
  }
}

export const isUserMasterAdmin = async (userId: number): Promise<boolean> => {
  let masterId = 0;
  try {
    const jwtPayload = getZtsJwtPayload();
    if (jwtPayload && jwtPayload.tenantId) {
      masterId = await getMasterAdminId(+jwtPayload.tenantId);
    }
  } catch (err) {
    LOG.error("Error reading master admin id", err);
  }
  return userId === masterId;
}

export const updateProfile = async (req: UpdateProfileRequest): Promise<void> => {
  const apiUrl = getApiUrl();
  await axios.put(`${apiUrl}/staff/profile`, req);
}

export const getValidCreCards = async (): Promise<AllValidCreditCard[]> => {
  const apiUrl = getApiUrl();
  const resp = await axios.get(`${apiUrl}/staff/0/allvalidcrecards`);
  if(resp.data.elements.size === 0){
    return [];
  }
  return resp.data.elements;
}
